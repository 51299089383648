import {App, Button, Col, Row, Select} from "antd";
import Title from "antd/es/typography/Title";
import {DownloadOutlined} from "@ant-design/icons";
import {BaseContent} from "../../components/base-content";
import React, {useContext, useState} from "react";
import {DoLoadStockReport} from "./hooks/do-load-stock-report";
import {DoLoadWalletReport} from "./hooks/do-load-wallet-report";
import Fund from "../../entities/fund";
import {DoLoadFunds} from "../../hooks/do-load-funds";
import {WorkingSystemConfigContext} from "../../services/working-system-config-provider";

export function ReportPage() {
  const wscContext = useContext(WorkingSystemConfigContext);
  const [funds, setFunds] = useState<[]>([]);

  const stockReport = DoLoadStockReport();
  const walletReport = DoLoadWalletReport();
  const {notification} = App.useApp();
  const fundsQuery = DoLoadFunds({manager: wscContext.value.manager});

  const onTap = async (type: string) => {
    notification.info({
      duration: 4,
      message: 'Montando seu relatório, você receberá por e-mail.',
    });

    const params = {
      funds: funds,
      quarter: parseInt(wscContext.value.quarter),
      manager: parseInt(wscContext.value.manager),
    };

    if (type === 'stock') stockReport.mutate(params);
    if (type === 'wallet') walletReport.mutate(params);
  }

  const fundOptions = () => {
    const funds = fundsQuery.data ?? [];

    return funds.map((it: Fund) => {
      return {
        key: it.id,
        value: it.id,
        label: it.name
      }
    })
  }

  const onFundsChage = (arr: []) => setFunds(arr);

  const labelFilter = (input: any, option: any): boolean => {
    return (option?.label ?? '').toLowerCase().includes(input.toLowerCase());
  };

  return (
    <BaseContent>
      <Row>
        <Col span={6}>
          <Select
            allowClear
            mode="multiple"
            options={fundOptions()}
            filterOption={labelFilter}
            placeholder='Fundo'
            style={{width: '100%'}}
            onChange={onFundsChage}
          />
        </Col>
      </Row>
      <br/>
      <Row>
        <Col span={3}>
          <Title level={4}>Relatórios</Title>
          <Button onClick={() => onTap('stock')} icon={<DownloadOutlined/>}>Estoque</Button>
          <p></p>
          <Button onClick={() => onTap('wallet')} icon={<DownloadOutlined/>}>Carteira</Button>
        </Col>
      </Row>
    </BaseContent>
  )
}