import {instance} from "../../../services/axios-config";
import {App} from "antd";
import {useMutation} from "react-query";
import {AxiosError} from "axios";
import {Manager} from "../../../entities/manager";

const createManager = async (manager: Manager) => {
  if (manager.id) {
    await instance.put(`/v1/manager/edit/${manager.id}`, manager);
  } else {
    await instance.post(`/v1/manager/create`, manager);
  }
}

export const DoCreateManager = () => {
  const {notification} = App.useApp();

  return useMutation(createManager, {
      onError: (error: AxiosError) => {
        const message = (error.response?.data as any)?.message ?? 'Erro interno';

        notification.error({
          duration: 7,
          message: message,
        });
      },
    }
  );
}