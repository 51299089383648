import {App} from "antd";
import {instance} from "../../../services/axios-config";
import {useMutation} from "react-query";
import {AxiosError} from "axios";

const loadStockReport = async (options: any) => {
  await instance.post('/v1/report/stock', options);
}

export function DoLoadStockReport() {
  const {notification} = App.useApp();
  const {mutate, isLoading} = useMutation(loadStockReport, {
      onError: (error: AxiosError) => {
        const message = (error.response?.data as any)?.message ?? 'Erro interno';

        notification.error({
          duration: 7,
          message: message,
        });
      },
    }
  );

  return {mutate, isLoading};
}