import SampleStock from "../../../entities/sample-stock";
import {instance} from "../../../services/axios-config";
import {useMutation, useQuery} from "react-query";
import {SampleData} from "../../archive-stock/service/do-load-sample-stock-list";


async function doLoadSamples({filters, id, quantity, quarter, manager}: any): Promise<SampleData> {
  console.log(quarter)
  const response = await instance.post(`/v1/sample_stock/next/${id}`, filters, {
    params: {
      quantity: quantity,
      'quarter': quarter,
      'manager': manager,
    },
  });

  return {
    items: response.data.items.map(SampleStock.fromJson),
    pageNumber: response.data.pageNumber,
    totalItems: response.data.totalItems,
  };
}

export function DoLoadNextSampleStock() {
  return useMutation(doLoadSamples);
}

export function DoLoadNextSampleStockQuery(query: any) {
  return useQuery(
    `/v1/sample_stock/next/${query.id}`,
    () => doLoadSamples(query),
    {
      staleTime: 60 * 1000,
    }
  );
}