import {instance} from "../../../services/axios-config";
import {App} from "antd";
import {useMutation} from "react-query";
import {AxiosError} from "axios";
import {Manager} from "../../../entities/manager";

const changeManagerStatus = async (manager: Manager) => {
  return instance.put(`/v1/manager/change_status/${manager.id}`);
}

export function DoChangeManagerStatus() {
  const {notification} = App.useApp();

  return useMutation(changeManagerStatus, {
      onError: (error: AxiosError) => {
        const message = (error.response?.data as any)?.message ?? 'Erro interno';

        notification.error({
          duration: 7,
          message: message,
        });
      },
    }
  );
}