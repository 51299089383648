import {useQuery} from "react-query"; 
import { instance } from "../services/axios-config";
import Fund from "../entities/fund";

async function doLoadFunds(params: any): Promise<Array<any>> {
  let query = {};
  if (params.manager) query = {manager: params.manager};

  const response = await instance.get('/v1/funds', {
    params: query,
  });

  return response.data.map((it: any) => Fund.fromMap(it));
}

export function DoLoadFunds(params: any) {
  return useQuery<Array<Fund>>(
   `/v1/funds`,
   () => doLoadFunds(params),
   {
     staleTime: 60 * 1000, 
   }
  );
}
