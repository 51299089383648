export class Manager {
  id?: number;
  name: string;
  document: string;
  dataStatus?: number;
  funds: Array<FundLink>;

  constructor(name: string, document: string, funds: Array<FundLink>) {
    this.name = name;
    this.document = document;
    this.funds = funds;
  }

  static fromJson({id, name, document, dataStatus, funds}: any): Manager {
    const m = new Manager(name, document, funds);
    m.id = id;
    m.dataStatus = dataStatus;

    return m;
  }
}

export class FundLink {
  id: number;
  name: string;

  constructor(id: number, name: string) {
    this.id = id;
    this.name = name;
  }
}