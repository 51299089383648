import React, {useEffect} from 'react'
import {Flex, Form, Input, Modal, Select} from "antd";
import PropTypes from "prop-types";
import {DoCreateManager} from "../hooks/do-create-manager";
import {FundLink, Manager} from "../../../entities/manager";
import Fund from "../../../entities/fund";

const message = {message: 'Campo obrigatório!'};

export default function ManagerFormModal(props: any) {
  const [form] = Form.useForm();
  const handleManagerQuery = DoCreateManager();

  useEffect(() => {
    form.setFieldValue(['manager', 'name'], props.manager?.name);
    form.setFieldValue(['manager', 'document'], props.manager?.document);
    form.setFieldValue(['manager', 'funds'], props.manager?.funds.map((it: FundLink) => it.id));
  }, [props.manager]);

  async function onSuccessfulSubmit({manager}: any) {
    const it = new Manager(manager.name, manager.document, manager.funds);
    it.id = props.manager?.id;
    await handleManagerQuery.mutateAsync(it);

    form.resetFields();
    props.onClose();
  }

  function onCancelSubmit() {
    form.resetFields();
    props.onClose();
  }

  const fundOptions = () => {
    return props.funds.map((it: Fund) => {
      return {
        key: it.id,
        value: it.id,
        label: it.name
      }
    })
  }

  const labelFilter = (input: any, option: any) : boolean => {
    return (option?.label ?? '').toLowerCase().includes(input.toLowerCase());
  };

  return (
    <Modal
      open={props.formVisible}
      destroyOnClose={true}
      centered={true}
      okText={'Confirmar'}
      onOk={form.submit}
      onCancel={onCancelSubmit}
      closable={false}
      maskClosable={false}
    >
      <Flex flex="fit-content">
        <Form
          form={form}
          labelCol={{span: 6}}
          wrapperCol={{span: 16}}
          style={{width: '100%'}}
          onFinish={onSuccessfulSubmit}
        >
          <Form.Item name={['manager', 'name']} label="Nome" rules={[{required: true, ...message}]}>
            <Input/>
          </Form.Item>
          <Form.Item name={['manager', 'document']} label="Documento"
                     rules={[{required: true, ...message}]}>
            <Input/>
          </Form.Item>
          <Form.Item label="Fundos" name={['manager', 'funds']} rules={[{required: true, ...message}]}>
            <Select options={fundOptions()} mode='multiple' allowClear filterOption={labelFilter}/>
          </Form.Item>
        </Form>
      </Flex>
    </Modal>
  );
}

ManagerFormModal.propTypes = {
  formVisible: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  manager: PropTypes.object,
  funds: PropTypes.array.isRequired,
}