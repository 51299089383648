import {instance} from "../../../services/axios-config";
import {App, UploadFile} from "antd";
import {useMutation} from "react-query";
import {AxiosError} from "axios";

interface SaveDocumentParams {
  sampleId: number;
  documentTypeId: number;
  file: UploadFile;
}

const saveDocument = async (params: SaveDocumentParams) => {
  const formData = new FormData();
  formData.append('params', JSON.stringify({sampleId: params.sampleId, documentTypeId: params.documentTypeId}));
  formData.append('file', params.file as any);

  await instance.post('/v1/sample_stock/file/add', formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    }
  })
}

export const DoSaveDocument = () => {
  const {notification} = App.useApp();
  const {mutate, isLoading} = useMutation(saveDocument, {
      onError: (error: AxiosError) => {
        const message = (error.response?.data as any)?.message ?? 'Erro interno';

        notification.error({
          duration: 7,
          message: message,
        });
      },
    }
  );

  return {mutate, isLoading};
}