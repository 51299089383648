import User from "../../../entities/user";
import {instance} from "../../../services/axios-config";
import {App} from "antd";
import {useState} from "react";
import {useMutation} from "react-query";
import {AxiosError} from "axios";

const changeUserStatus = async (user: User) => {
  return instance.put(`/v1/user/change_status/${user.id}`);
}

export function DoChangeUserStatus() {
  const {notification} = App.useApp();

  return useMutation(changeUserStatus, {
      onError: (error: AxiosError) => {
        const message = (error.response?.data as any)?.message ?? 'Erro interno';

        notification.error({
          duration: 7,
          message: message,
        });
      },
    }
  );
}