import {instance} from "../services/axios-config";
import {useQuery} from "react-query";
import {Manager} from "../entities/manager";

const loadBase = async () => {
  const response = await instance.get(`/manager/list`);
  return  response.data.map((it: any) => Manager.fromJson(it));
}

export function DoLoadManagers() {
  return useQuery<Array<Manager>>(
    `/v1/manager/list`,
    loadBase,
    {
      staleTime: 60 * 1000,
    }
  );
}