import {useMutation} from "react-query";
import {instance} from "../services/axios-config";
import {App} from "antd";
import {AxiosError} from "axios";

interface Credentials {
  username: string;
  password: string;
}

const login = async (credentials: Credentials) => {
  const response = await instance.post('/login', credentials);
  return response.data;
}

export const UseLogin = () => {
  const {notification} = App.useApp();

  return useMutation(login, {
    onError: (error: AxiosError) => {
      if (error.response?.status === 403) {
        notification.error({
          message: 'Credenciais incorretas',
          duration: 5,
        })
      } else {
        notification.error({
          message: 'Erro interno ao tentar se conectar.',
          description: 'Contate o administrador',
          duration: 15,
        })
      }
    },
  });
}